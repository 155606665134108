/**
 * Company: SPHMedia
 * Description:  AboutUs page
 */
import { ReactElement } from "react";

/**
 * AboutUs
 * Description: About Us Page for the web core team
 * @returns The rendered React element
 */
export default function AboutUs(): ReactElement {
  const teamMembers = [
    { name: "Terence Tham", role: "Webcore Manager" },
    { name: "Guoyou", role: "Webcore Lead" },
  ];

  return (
    <div>
      <h1>About Us</h1>
      <p>
        Welcome to our web core team! We are a group of passionate individuals
        dedicated to creating outstanding web applications. Get to know our team
        members below:
      </p>

      <ul>
        {teamMembers.map((member, index) => (
          <li key={index}>
            <strong>{member.name}</strong> - {member.role}
          </li>
        ))}
      </ul>

      {/* Add more content about your team, projects, and mission */}
    </div>
  );
}
