type IndexItem = {
  name: string;
  index: string;
};

export const CAAS_INDEXES =
  "caas-en-bt-online,straitstimes,zaobao,beritaharian,thinkchina,harpersbazaar,tamilmurasu";

export const CAAS_BT_INDEX = "caas-en-bt-online";
export const CAAS_ST_INDEX = "straitstimes";
export const CAAS_ZB_INDEX = "zaobao";
export const CAAS_BH_INDEX = "beritaharian";
export const CAAS_TC_INDEX = "thinkchina";
export const CAAS_HBS_INDEX = "harpersbazaar";
export const CAAS_TM_INDEX = "tamilmurasu";

export const listOfIndexes: IndexItem[] = [
  { name: "ALL", index: CAAS_INDEXES },
  { name: "BT", index: CAAS_BT_INDEX },
  { name: "ST", index: CAAS_ST_INDEX },
  { name: "ZB", index: CAAS_ZB_INDEX },
  { name: "BH", index: CAAS_BH_INDEX },
  { name: "TC", index: CAAS_TC_INDEX },
  { name: "HBS", index: CAAS_HBS_INDEX },
  { name: "TM", index: CAAS_TM_INDEX },
];
