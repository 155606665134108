import { MySphLightboxHelper } from "@sphtech/web2-core/auth";
import { ReactElement } from "react";

type LoginButtonProps = {
  title?: string;
};

/**
 * LoginButton
 *
 * Description: Button that user clicks to initiate the login process via MySPH Lightbox.
 * @param {LoginButtonProps} props.title Display text of the button. Defaults to "LOG IN".
 * @returns The rendered React element representing the button
 */
export default function LoginButton({
  title = "LOG IN",
}: LoginButtonProps): ReactElement {
  return (
    <button
      onClick={() => {
        MySphLightboxHelper.openLogin();
      }}
    >
      {title}
    </button>
  );
}
