import noImage from "@app/assets/no-image.jpg";
import { NoSSR } from "@sphtech/web2-core/components";
import type { TRouteWithRedirect } from "@sphtech/web2-core/ssr";
import { CustomContext, useRouteContext } from "@sphtech/web2-core/ssr";
import { ResponseType } from "@sphtech/web2-core/ssr";
import { ProcessedCueArticle } from "@src/app/hooks/useOSResponse";
import { Fragment } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

import StatusHandler from "../StatusHandler/StatusHandler";
import styles from "./Article.module.css";

export default function ArticlePage() {
  const routeContext: CustomContext<
    TRouteWithRedirect<ProcessedCueArticle, string>
  > = useRouteContext();
  const dataLoaderResponse = routeContext.context;
  if (dataLoaderResponse.type !== ResponseType.SUCCESS) {
    return <StatusHandler response={dataLoaderResponse} />;
  }

  const article = dataLoaderResponse.payload;
  /**
   * Check the data structure before trusting this as different
   * publications may have different structures.
   *
   * TODO: (Please fix)
   * For image optimizing, check out CaaS guide to using the image optimizer
   * https://github.com/SPHTech-Applications/caas-platform-img-optimisation-lambda/blob/main/docs/quick-start.md#how-to-use-the-image-optimisation-api
   */
  const optimized = article.imageField?.url ?? noImage;

  return (
    <main>
      <article className={styles.article}>
        {article.elements.map((e, i: number) => {
          if (e.type === "headline") {
            const headline =
              e.fields.find((f) => f.name === "headline")?.value ?? "";

            return (
              <Fragment key={i}>
                <Helmet>
                  <title>{`${headline} — The Citrus Times`}</title>
                </Helmet>

                <Link to={`/${article.sections.name}`}>
                  {article.sections.name}
                </Link>
                <h1>{headline}</h1>

                <img className={styles.hero} src={optimized} alt="fixme" />
                <div className={styles.info}>
                  <div>{article.authors?.map((a) => a.name).join(", ")}</div>
                  <div>
                    Published:{" "}
                    <NoSSR>
                      {new Date(article.createdDate).toLocaleString()}
                    </NoSSR>
                  </div>
                </div>
              </Fragment>
            );
          } else if (e.type === "paragraph") {
            return (
              <p key={i}>
                {e.fields.find((f) => f.name === "paragraph")?.value}
              </p>
            );
          } else if (e.type === "slug" || e.type === "standfirst") {
            return null;
          } else if (e.type === "kicker") {
            return (
              <p key={i}>{e.fields.find((f) => f.name === "kicker")?.value}</p>
            );
          } else {
            return (
              <details key={i}>
                <summary>
                  <code>Unimplemented block {e.type}</code>
                </summary>
                <pre style={{ overflow: "auto" }}>
                  {JSON.stringify(e, null, 2)}
                </pre>
              </details>
            );
          }
        })}
      </article>
    </main>
  );
}
