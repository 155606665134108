import type { TRouteWithoutRedirect } from "@sphtech/web2-core/ssr";
import { CustomContext, useRouteContext } from "@sphtech/web2-core/ssr";
import { ResponseType } from "@sphtech/web2-core/ssr";
import { OSResponse, PartialCueArticle } from "@typings/Opensearch";
import { Link } from "react-router-dom";

import StatusHandler from "../StatusHandler/StatusHandler";

export default function SectionPage() {
  const routeContext: CustomContext<
    TRouteWithoutRedirect<OSResponse<PartialCueArticle>, string>
  > = useRouteContext();
  const dataLoaderResponse = routeContext.context;
  if (dataLoaderResponse.type !== ResponseType.SUCCESS) {
    return <StatusHandler response={dataLoaderResponse} />;
  }

  const data = dataLoaderResponse.payload;
  const articles = data.payload.hits.hits;
  const sectionName =
    articles[0]?._source?.data?.context?.sections &&
    articles[0]?._source?.data?.context?.sections?.length > 0
      ? articles[0]?._source?.data?.context?.sections[0]?.name
      : "";

  return (
    <main>
      <h3>
        Section: <u>{sectionName}</u>
      </h3>
      <ul>
        {articles.map((article) => (
          <li key={article._source.data.context.id}>
            <Link to={article._source.data.context.urlPath}>
              <h4>{article._source.data.context.title}</h4>
            </Link>
            <p>
              {
                article._source.data.context.elements.find(
                  (ele) => ele.type === "headline",
                )?.fields[0].value
              }
            </p>
          </li>
        ))}
      </ul>
    </main>
  );
}
