/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import noImage from "@app/assets/no-image.jpg";
import bhIcon from "@app/assets/pub-logos/bh.png";
import btIcon from "@app/assets/pub-logos/bt.png";
import hbsIcon from "@app/assets/pub-logos/hbs.png";
import stIcon from "@app/assets/pub-logos/st.png";
import tcIcon from "@app/assets/pub-logos/tc.png";
import tmIcon from "@app/assets/pub-logos/tm.png";
import zbIcon from "@app/assets/pub-logos/zb.png";
import { NoSSR } from "@sphtech/web2-core/components";
import type { TRouteWithoutRedirect } from "@sphtech/web2-core/ssr";
import { CustomContext, useRouteContext } from "@sphtech/web2-core/ssr";
import { ResponseType } from "@sphtech/web2-core/ssr";
import LoginButton from "@src/app/components/LoginButton/LoginButton";
import { ProcessedCueArticle } from "@src/app/hooks/useOSResponse";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

import { listOfIndexes } from "../caas";
import styles from "./Home.module.css";

const ICONS: Record<string, string> = {
  bt: btIcon,
  st: stIcon,
  beritaharian: bhIcon,
  harpersbazaar: hbsIcon,
  zaobao: zbIcon,
  thinkchina: tcIcon,
  tamilmurasu: tmIcon,
};

export default function HomePage() {
  const [sort, setSort] = useState<number[]>();
  const routeContext: CustomContext<
    TRouteWithoutRedirect<ProcessedCueArticle[], string>
  > = useRouteContext();
  const [selectedOption, setSelectedOption] = useState(
    listOfIndexes.find((x) => x.name === "ALL")?.name,
  );

  const handleDropdownChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ): void => {
    const fetchDataAndUpdateState = async (): Promise<void> => {
      setData([]);
      try {
        setSelectedOption(e.target.value);
        const response = await fetchMoreStoriesData(
          listOfIndexes.find((x) => x.name === e.target.value)?.index ?? "",
        );
        if (response.statusCode === 200) {
          setData(response.payload);
        } else setDataLoaderResponse(response);
      } catch (e) {
        const error = e as Error;
        console.error("Error fetching data:", error);
      }
    };

    void fetchDataAndUpdateState();
  };

  const handleLoadMore = (): void => {
    const fetchDataAndUpdateState = async (): Promise<void> => {
      console.log({ Check: sort === undefined });
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (sort === undefined) setSort(data[data.length - 1].sort);
      try {
        console.log({ moreData: data.findLast((x) => x.sort)?.sort });
        const response = await fetchMoreStoriesData(
          listOfIndexes.find((x) => x.name === selectedOption)?.index ?? "",
          sort,
          9,
        );
        if (response.statusCode === 200) {
          setSort(response.payload[response.payload.length - 1].sort);
          setData([...data, ...response.payload]);
        }
      } catch (e) {
        const error = e as Error;
        console.error("Error fetching more data:", error);
      }
    };

    void fetchDataAndUpdateState();
  };

  const [dataLoaderResponse, setDataLoaderResponse] = useState(
    routeContext.context,
  );

  if (dataLoaderResponse.type === ResponseType.CLIENT_ERROR) {
    throw new Error("Client Error");
  } else if (dataLoaderResponse.type === ResponseType.SERVER_ERROR) {
    throw new Error("Server Error");
  }
  const [data, setData] = useState(dataLoaderResponse.payload);

  return (
    <main className={styles.home}>
      <Helmet>
        <title>The Citrus Times</title>
      </Helmet>
      <LoginButton></LoginButton>
      <h2 className={styles.masthead}>🍊 The Citrus Times</h2>
      select Index :{" "}
      <select value={selectedOption} onChange={handleDropdownChange}>
        <option value="">Select an option</option>
        {listOfIndexes.map(({ name }) => (
          <option key={name} value={name}>
            {name}
          </option>
        ))}
      </select>
      <ul className={styles.homeArticles}>
        {data.map((article, index) => {
          const mobileImageWidth = 768;
          const imageRatio = 1.5;
          /**
           * TODO
           * Check the data structure before trusting this as different
           * publications may have different structures.
           *
           * TODO: (Please fix)
           * For image optimizing, check out CaaS guide to using the image optimizer
           * https://github.com/SPHTech-Applications/caas-platform-img-optimisation-lambda/blob/main/docs/quick-start.md#how-to-use-the-image-optimisation-api
           */
          const optimized = article.imageField?.url ?? noImage;
          return (
            <li key={article.id}>
              <Link to={article.linkTo}>
                <img
                  width={mobileImageWidth}
                  height={index == 0 ? mobileImageWidth / imageRatio : 200}
                  src={optimized}
                  alt="No article img"
                />
                <div style={{ display: "flex", gap: 8 }}>
                  <img
                    width={40}
                    height={40}
                    src={ICONS[article.pub] ?? ""}
                    alt=""
                  />
                  <div>
                    <div className={styles.section}>
                      {article.sections.name}
                    </div>
                    <div className={styles.title}>{article.title}</div>
                    <div className={styles.date}>
                      <div className={styles.title}>{article.title}</div>
                      <div className={styles.date}>
                        <NoSSR>
                          {new Date(article.createdDate).toLocaleString(
                            "en-SG",
                            {
                              hour12: false,
                              month: "long",
                              day: "numeric",
                              hour: "numeric",
                              minute: "numeric",
                              timeZoneName: "short",
                            },
                          )}
                        </NoSSR>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </li>
          );
        })}
      </ul>
      <button onClick={handleLoadMore}>Load More</button>
    </main>
  );
}

async function fetchMoreStoriesData(
  index: string,
  sort?: number[],
  size?: number,
): Promise<TRouteWithoutRedirect<ProcessedCueArticle[], string>> {
  try {
    const response = await fetch("/_plat/api/v1/getHomeData", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        index: index,
        sort: sort,
        size: size ?? 22,
      }),
    });
    const resData = await response.json();
    return resData.data as TRouteWithoutRedirect<ProcessedCueArticle[], string>;
  } catch (e) {
    const error = e as Error;
    return {
      type: ResponseType.SERVER_ERROR,
      statusCode: 500,
      payload: error.message,
    };
  }
}
