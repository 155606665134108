import AboutUs from "@pages/AboutUs";
import ArticlePage from "@pages/Article/Article.tsx";
import HomePage from "@pages/Home/Home.tsx";
import SectionPage from "@pages/Section/Section.tsx";
import { AuthCallback } from "@sphtech/web2-core/auth";
import { fetchContext, ResponseType } from "@sphtech/web2-core/ssr";
import { getClientId, getIssuer } from "@web2/web2-helpers/auth/MySphConfig.ts";
import { RouteObject } from "react-router-dom";

import App from "./App";
import StatusHandler from "./pages/StatusHandler/StatusHandler";

const errorElement = import.meta.env.PROD ? (
  <StatusHandler clientSideError />
) : undefined;

export const reactRouterRoutes: RouteObject[] = [
  {
    path: "/",
    element: <App />,
    children: [
      {
        index: true,
        element: <HomePage />,
        errorElement,
        loader: fetchContext,
      },
      /**
       * Integration guide: https://sph.atlassian.net/wiki/x/V4GqY
       */
      {
        path: "/oauth/callback",
        element: <AuthCallback clientId={getClientId()} issuer={getIssuer()} />,
        errorElement,
      },
      {
        path: "/article/:id",
        element: <ArticlePage />,
        errorElement,
        loader: fetchContext,
      },
      {
        path: "/about-us",
        element: <AboutUs />,
        errorElement,
      },
      {
        path: "/:section/:slug",
        element: <ArticlePage />,
        errorElement,
        loader: fetchContext,
      },
      {
        path: "/:section/:second/:slug",
        element: <ArticlePage />,
        errorElement,
        loader: fetchContext,
      },
      {
        path: "/:level1",
        element: <SectionPage />,
        errorElement,
        loader: fetchContext,
      },
      // {
      //   path: "/:level1/:level2",
      //   element: <SectionPage />,
      //   loader: fetchContext,
      // },
      {
        path: "*",
        element: (
          <StatusHandler
            response={{
              type: ResponseType.CLIENT_ERROR,
              statusCode: 404,
            }}
          />
        ),
        errorElement,
      },
    ],
  },
];

export const routes = reactRouterRoutes.reduce(
  (_routes: string[], currentRoute: RouteObject) => {
    if (currentRoute.path) {
      _routes = [..._routes, currentRoute.path];
    }

    if (currentRoute.children) {
      for (const child of currentRoute.children) {
        if (child.path) {
          _routes = [..._routes, child.path];
        }
      }
    }

    return _routes;
  },
  [],
);
