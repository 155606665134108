import "./global.css";

import { useMySphLightbox } from "@sphtech/web2-core/auth";
import { RenderContext } from "@sphtech/web2-core/ssr";
import NeuronProvider from "@web2/providers/NeuronProvider";
import { ReactElement, StrictMode, useContext } from "react";
import { HelmetProvider } from "react-helmet-async";
import { Outlet, ScrollRestoration } from "react-router-dom";

import {
  getClientId,
  getLightboxUrl,
} from "../../.web2/web2-helpers/auth/MySphConfig";

export default function App(): ReactElement {
  const renderContext = useContext(RenderContext);

  /**
   * Integration guide: https://sph.atlassian.net/wiki/x/V4GqY
   */
  useMySphLightbox({
    lightboxUrl: getLightboxUrl(),
    clientId: getClientId(),
  });

  return (
    <StrictMode>
      <NeuronProvider>
        <ScrollRestoration />
        <HelmetProvider context={renderContext.helmet}>
          <Outlet />
        </HelmetProvider>
      </NeuronProvider>
    </StrictMode>
  );
}
